<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>User Banned Detail</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="userReportSelect"
        :items="userStatus"
        :loading="$store.state.progress"
        item-value="key"
        item-text="value"
        hide-details
        label="User Status"
      ></v-combobox>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="bannedReasonSelect"
        :items="bannedReason"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        hide-details
        label="Banned Reason"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="timeleftSelect"
        :items="timelefts"
        :loading="$store.state.progress"
        item-value="id"
        v-if="userReportSelect.key == -1"
        item-text="value"
        hide-details
        label="Time Left"
      ></v-combobox>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="timeleftManuel"
        label="Time Left"
        v-if="userReportSelect.key == -1"
        hide-details
        placeholder="seconds"
      ></v-text-field>
      <v-switch
        v-model="isChipDown"
        :label="`Is Chip Down`"
        hide-details
      ></v-switch>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-text-field
        v-model="chipAmount"
        label="Chip"
        :disabled="!isChipDown"
        hide-details
        placeholder="chip"
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color=" green" dark @click="userBanned()" class="mb-2"
        >User Ban</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="detailHeaders"
      hide-default-footer
      :items="$store.state.userReports.report"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.createdDate="{ item }">
        <div>
          {{ new Date(item.createdDate * 1000) | formatDate }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div>
          {{ item.status | toUserStatus }}
        </div>
      </template>
      <template v-slot:item.reasons="{ item }">
        <div v-if="item.reasons">
          {{ item.reasons | toBannedReasons }}
        </div>
      </template>

      <template v-slot:item.room="{ item }">
        <div v-if="item.room">
          {{ item.room.n }}
        </div>
      </template>
      <template v-slot:item.fromUserId="{ item }">
        <UserDetailCard
          :userId="item.fromUserId"
          :pictureUrl="item.fromPictureUrl"
          :userName="item.fromUserName"
        />
      </template>
      <template v-slot:item.userId="{ item }">
        <UserDetailCard
          :userId="item.userId"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import userStatus from "@/lib/userStatus";
import bannedReason from "@/lib/bannedReason";
export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  name: "ReportDetailCard",
  watch: {
    async clicked() {
      await this.detail();
    },
  },
  props: {
    clicked: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
      description: "UserId",
    },
  },
  data() {
    return {
      currentPage: 1,
      detailHeaders: [
        { text: "From User", value: "fromUserId" },
        { text: "User", value: "userId" },
        { text: "Total Reports", value: "reportCount" },
        { text: "Reasons", value: "reasons" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "createdDate" },
        { text: "Message", value: "message" },
        { text: "Room", value: "room" },
      ],
      dialog: false,
      chatDialog: false,
      userDetail: {},
      chipAmount: 0,
      isChipDown: false,
      timeleftSelect: null,
      timeleftManuel: null,
      timelefts: [
        { value: "10 Minutes", key: 60 * 10 },
        { value: "1 Hours", key: 60 * 60 },
        { value: "4 Hours", key: 60 * 60 * 4 },
        { value: "12 Hours", key: 60 * 60 * 12 },
        { value: "24 Hours", key: 60 * 60 * 24 },
        { value: "3 Days", key: 60 * 60 * 24 * 3 },
        { value: "7 Days", key: 60 * 60 * 24 * 7 },
        { value: "14 Days", key: 60 * 60 * 24 * 14 },
        { value: "1 Months", key: 60 * 60 * 24 * 30 },
        { value: "3 Months", key: 60 * 60 * 24 * 30 * 3 },
      ],
      bannedReason: bannedReason,
      userStatus: userStatus,
      user: null,
      userReportSelect: { key: 0, value: "Active" },
      bannedReasonSelect: { key: -1, value: "All" },
      headers: [
        { text: "From User", value: "fromUserId" },
        { text: "User", value: "userId" },
        { text: "Total Reports", value: "reportCount" },
        { text: "Reasons", value: "reasons" },
        { text: "Banned Reason", value: "bannedReason" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "createdDate" },
        { text: "Message", value: "message" },
        { text: "Room", value: "room" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async detail() {
      this.isChipDown = false;
      await this.$store.dispatch("userReports/getById", this.userId);
      await this.$store.dispatch("users/getById", this.userId);
      this.user = this.$store.state.users.user;
      this.chipAmount = this.$store.state.users.user.chipAmount;
      this.userDetail = {
        userId: this.userId,
        reports: this.$store.state.userReports.report,
      };
      this.dialog = true;
    },

    async userBanned() {
      await this.$store.dispatch("userReports/postBannedToUser", {
        userId: this.userId,
        status: this.userReportSelect.key,
        reasonCode: this.bannedReasonSelect.key,
        isChipDown: this.isChipDown,
        timeLeft:
          this.timeleftManuel || this.timeleftSelect
            ? parseInt(
                this.timeleftManuel
                  ? this.timeleftManuel
                  : this.timeleftSelect.key
              )
            : 0,
        chipAmount: parseInt(this.chipAmount),
      });
      this.dialog = false;
      this.$store.dispatch("setAlert");
    },
    async chatBanned(item) {
      await this.$store.dispatch("userReports/chatBanned", {
        userId: item,
        timeLeft: this.timeleftSelect.key,
      });
      this.dialog = false;
    },
  },
  async mounted() {},
};
</script>

<style>
.v-dialog {
  background: #424242 !important;
}
</style>
